import React from "react";
import PageWrapper from "../components/PageWrapper";
import ContentA from "../sections/about/acontent";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head"
import Ethics from "../sections/index/indexethics.js";
import Trend from '../components/trends.js';
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/about-derma-clinic.jpg";
import Tabs from '../shared/Tabs.js';


const About = () => {
  const titleText = "About Dermamina";
  const textContent = "Bespoke treatments tailored to your individual needs.";
  return (
    <>
    <Head title="About Dermamina" image="/images/dermamina-home.jpg"  description="experts in non surgical nose job Rhinoplasty London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
      <PageWrapper headerDark>
      <HeroComponent
    itext='<span style="color: #1a1a1a;">About </span> <span style="color: #00aec7;">Dermamina</span>'
    secondText="Bespoke treatments tailored to your individual needs."
    videoAlt="About Non Surgical Treatments"
    imgVideo={imgVideo} // Specify your image URL here
  />
     
      <ContentA />
<Tabs />

        <Ethics />
      
        <Brand />
        <Saveface />
        <Trend />
    
      </PageWrapper>
   
    </>
  );
};
export default About;